import { getUserToken } from '~/services/supabase'
import { CheckoutPayload, CheckoutResponse } from '~/types/stripe/checkout'

const getCheckoutLink = async (cart: CheckoutPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/checkout', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(cart)
    })
    const result: CheckoutResponse = await data.json()
    return result.url
  }
}

export {
  getCheckoutLink
}