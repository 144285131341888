import { Component } from 'solid-js'
import { LoadingIconProps } from '~/components/loading-icon/loading-icon.interfaces'
import styles from '~/components/loading-icon/loading-icon.module.scss'
import Icon from '~/assets/icons/loading.svg'

const LoadingIcon: Component<LoadingIconProps> = ({ size }) => {
  const sizeProps = size ? {
    width: `${size}px`,
    height: `${size}px`,
  } : undefined

  return (
    <Icon class={styles.loadingIcon} style={sizeProps} />
  )
}

export default LoadingIcon
